'use strict';

var define = require('define-properties');
var getPolyfill = require('./polyfill');
module.exports = function shimAssign() {
  var polyfill = getPolyfill();
  define(Object, {
    assign: polyfill
  }, {
    assign: function () {
      return Object.assign !== polyfill;
    }
  });
  return polyfill;
};