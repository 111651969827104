'use strict';

var isString = require('is-string');
var isNumber = require('is-number-object');
var isBoolean = require('is-boolean-object');
var isSymbol = require('is-symbol');
var isBigInt = require('is-bigint');

// eslint-disable-next-line consistent-return
module.exports = function whichBoxedPrimitive(value) {
  // eslint-disable-next-line eqeqeq
  if (value == null || typeof value !== 'object' && typeof value !== 'function') {
    return null;
  }
  if (isString(value)) {
    return 'String';
  }
  if (isNumber(value)) {
    return 'Number';
  }
  if (isBoolean(value)) {
    return 'Boolean';
  }
  if (isSymbol(value)) {
    return 'Symbol';
  }
  if (isBigInt(value)) {
    return 'BigInt';
  }
};