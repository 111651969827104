'use strict';

var defineProperties = require('define-properties');
var callBind = require('call-bind');
var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var shim = require('./shim');
var polyfill = callBind.apply(getPolyfill());
// eslint-disable-next-line no-unused-vars
var bound = function assign(target, source1) {
  return polyfill(Object, arguments);
};
defineProperties(bound, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
module.exports = bound;