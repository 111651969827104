import { Type } from "class-transformer";

import { User } from "../../../../auth/models/user.model";
import { Company } from "../../../../models/company.model";
import { Label } from "../../../../models/label.model";
import { Price } from "../../../../models/price.model";
import { FintechProduct } from "./fintech-product.model";
import { WorkflowGroup, WorkflowHistoryStep, WorkflowInstance } from "./workflow.model";

class ApplicationProfile {
  participant: string[];
  roles: string[];
  user_id: number;
}

export class FintechAdditional {
  concept?: string;
  value?: number;
  function?: {
    arguments: { [key: string]: string | number };
    body: string;
  }
}

export class FintechApplication extends WorkflowInstance {
  /** Agree's internal unique identifier. */
  readonly id: string;
  /** Agree's User. */
  readonly user: User;

  @Type(() => Date)
  readonly created_at: Date;

  @Type(() => Date)
  readonly updated_at: Date;

  @Type(() => Date)
  readonly due_date: Date;

  @Type(() => Company)
  readonly supplier?: Company;

  @Type(() => Company)
  readonly applicant: Company;

  @Type(() => Company)
  readonly company: Company;

  readonly qualification_id: string;

  @Type(() => FintechProduct)
  readonly product: FintechProduct;

  /** Line of credit requested. */
  requested?: Price;

  /** Line of credit approved. */
  approved?: Price;

  additionals?: FintechAdditional[];

  profile: ApplicationProfile;

  parent?: string; // Parent Application ID
  childs?: string[]; // Child Applications IDs

  /** For labeleable entities. */
  get entity(): string { return 'financial-application'; }

  readonly labels?: Label[];

  public lastStepFrom(group: WorkflowGroup): WorkflowHistoryStep {
    if (this.current.group.order === group.order) {
      // This is the current group
      return this.current;
    } else {
      // This is a past group
      if (this.history?.length > 0) {
        const groupSteps = this.history.filter(step => {
          return step.group.name + '_' + step.group.order === group.name + '_' + group.order &&
            step.type !== 6; // Choices step should be ignored
        }).sort((a, b) => (a.arrived_on && b.arrived_on) ? b.arrived_on.getTime() - a.arrived_on.getTime() : 0);

        return groupSteps[0];
      } else {
        // There is no history, probably a migrated application
        return this.current;
      }
    }
  }

  constructor(data: Partial<FintechApplication> = {}) {
    super();
    this.requested = new Price();

    Object.assign(this, data);
  }
}
