import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { DeliveryTypeGroup } from '../../../../../models/delivery-type-group.model';
import { DeliveryType } from '../../../../../models/delivery-type.model';
import { GroupBy } from '../../../../../models/group-by.model';
import { Media } from '../../../../../models/media.model';
import { PaymentCondition } from '../../../../../models/payment-condition.model';
import { Price } from '../../../../../models/price.model';
import { Product } from '../../../../../models/product.model';
import { CompanyService } from '../../../../../services/company.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { HubSpotService } from '../../../../../services/hub-spot.service';
import { LocationService } from '../../../../../services/location.service';
import { MarketService } from '../../../../../services/market.service';
import { startDay } from '../../../../../utilities/date';
import { genCropList } from '../../../../../utilities/setProp';
import { Order } from '../../models/order.model';
import { OrderService } from '../../services/order.service';

/**
 * Shows a modal for creating and editing [[Order|Orders]].
 *
 * ## Usage
 * ``` html
 * <create-order-modal #createOrder="createOrder"
 * [(order)]="modal_order"
 * (success)="saveOrder()"
 * (onCancel)="cancelEditing()"
 * [edit]="editingOrder !== undefined"
 * [excludeFields]="['paymentCondition']"
 * fullmode="true"></create-order-modal>
 * ```
 *
 * Then, to open the modal:
 * ``` javascript
 * this.createOrder.show();
 * ```
 */
@Component({
  selector: 'create-order-modal',
  exportAs: 'createOrder',
  templateUrl: './create-order-modal.component.html',
  styleUrls: ['./create-order-modal.component.scss']
})
export class CreateOrderModalComponent implements OnInit, OnDestroy {

  @ViewChild('modalTemplate', { static: true }) private readonly modalTemplate: TemplateRef<any>;

  @Input()
  @Output()
  public order: Order;

  /**
   * Fullmode requests more details of the [[Order]] and makes all the fields
   * required.
   */
  @Input() public fullmode: boolean;
  /**
   * Indicates to the component if it is an edition of an existing [[Order]] or
   * the creation of a new one.
   */
  @Input() public edit: boolean;
  /**
   * Fields of the order that want to be excluded.
   *
   * _Not all fields supported yet._
   */
  @Input() public excludeFields: Array<string> = [];
  /** Amount to settle. */
  @Input() public amount: Price;
  @Input() private ordersFrom: Company[];

  /** Triggered on form submit. */
  @Output() readonly success = new EventEmitter();
  /** Triggered on modal cancel. */
  @Output() readonly onCancel = new EventEmitter();

  public chambers: Location[] = [];
  public company: Company;
  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[] = [];
  public deletedFile = new EventEmitter();
  /** List of crop years. */
  public cropList: string[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public minPaymentDate: Date; // Requires today initialization
  public payment_conditions: PaymentCondition[] = [];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public products: Product[] = [];
  public quantityTypes: any[] = [];
  public today_start: Date;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private mode: 'manual' | 'market';
  private payment_conditions_default: PaymentCondition[] = [];
  private subscriptions: Subscription[] = [];
  private succesfullySubmitted: boolean;

  /** @ignore */
  constructor(
    private companyService: CompanyService,
    private currentDate: CurrentDateService,
    /** @ignore */
    public locationService: LocationService,
    private modalService: BsModalService,
    private marketService: MarketService,
    public hubSpotService: HubSpotService,
    private orderService: OrderService
  ) {
    this.today_start = startDay(this.currentDate.get());
    this.minPaymentDate = new Date(this.today_start.getTime() + 24 * 60 * 60 * 1000);
    this.cropList = genCropList();
  }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;
      
      this.company = company;
    }));
    this.subscriptions.push(this.marketService.watchProducts().subscribe(response => {
      if (response) this.products = response.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
    }));
    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(response => {
      this.currencies = response;
    }));
  }

  private loadMarketData(): void {
    this.loading = true;
    this.subscriptions.push(forkJoin([
      this.orderService.getDeliveryTypes(this.company.market.id),
      this.orderService.getPaymentConditions(),
      this.orderService.getExtraAttributes<Location>('camara_arbitral'),
      this.orderService.getQuantityTypes()
    ]).pipe(
      map(([delivery_types, payment_conditions, chambers, quantityTypes]) => {
        return {
          delivery_types,
          payment_conditions,
          chambers,
          quantityTypes
        };
      })
    ).subscribe(values => {
      this.marketDataLoaded(values);
    }));
  }

  private marketDataLoaded(values: {
    delivery_types: GroupBy<DeliveryTypeGroup, DeliveryType>[];
    payment_conditions: PaymentCondition[];
    chambers: any[];
    quantityTypes: any[];
  }): void {
    this.quantityTypes = values.quantityTypes;
    // Payment conditions
    this.payment_conditions_default = values.payment_conditions;
    this.payment_conditions = values.payment_conditions;
    // Chambers
    if (this.company.market.configuration.order.arbitration_chamber.enabled) this.chambers = values.chambers;

    // Set default values here
    if (this.order.business_detail) {
      // Delivery type
      if (this.order.business_detail.delivery.delivery_type == undefined) {
        let dt_results = [];
        values.delivery_types.forEach(group => {
          group.values.forEach(delivery_type => {
            if (delivery_type.operation_types.indexOf(this.order.operation_type) !== -1 &&
              delivery_type.active) {
              dt_results.push(delivery_type);
            }
          });
        });
        if (dt_results.length === 1) {
          // Assign this delivery type to the order
          this.order.business_detail.delivery.delivery_type = dt_results[0];
        } // If there is more than one result, user should be prompted to select
      }

      // Delivery period
      if (this.order.business_detail.delivery.range &&
        this.order.business_detail.delivery.range.length) {
        // Make sure the from date is no earlier than today
        const fromDate = Math.max(this.order.business_detail.delivery.range[0].getTime(), this.today_start.getTime());
        // Make sure the to date is no earlier than the from date
        const toDate = Math.max(this.order.business_detail.delivery.range[1].getTime(), fromDate);

        this.order.business_detail.delivery.range = [new Date(fromDate), new Date(toDate)];
      }

      // Quantity types
      if (!this.order.business_detail.quantity.type) {
        this.order.business_detail.quantity.type = this.quantityTypes[0];
      }

      // Currencies
      if (!this.order.business_detail.price.value) {
        this.order.business_detail.price.unit = this.currencies[0];
      }

      // Price
      if (!this.order.business_detail.price.type) {
        this.order.business_detail.price.type = 'flat';
        // delete this.order.business_detail.price.month;
        // delete this.order.business_detail.price.year;
      }

      // Delivery places
      // this.order.business_detail.delivery.locations = [];
    }

    // Products
    // Product selection workaround
    // Product object is retrieved with minimal data, this populates the attribute with the complete object
    if (this.order.product) this.order.product = this.products.filter(product => product.id === this.order.product.id)[0];

    this.loading = false;
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: any; }, b: { id: any; }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  public deleteImage(event): void {
    let file_id = event.file.id;

    this.afterDelete(file_id);
    setTimeout(() => {
      this.deletedFile.emit();
    });
  }

  private afterDelete(file_id: string): void {
    let temp: Array<Media> = [];
    this.order.media.forEach(media => {
      if (media.id !== file_id) {
        temp.push(media);
      }
    });
    this.order.media = temp;
  }

  public changeProduct(): void {
    this.order.product_detail.quality = {};

    if (this.order.product.attributes.payment_conditions) {
      this.payment_conditions = this.order.product.attributes.payment_conditions;
      this.order.payment_detail.payment_condition = this.payment_conditions[0];
    } else {
      this.payment_conditions = this.payment_conditions_default;
    }

    for (let i in this.order.product.attributes.quality) {
      let attribute = this.order.product.attributes.quality[i];
      if (attribute.element !== "textarea") {
        if (attribute.empty) {
          this.order.product_detail.quality[i] = attribute.empty;
        } else {
          this.order.product_detail.quality[i] = attribute.values[0];
        }
      } else {
        this.order.product_detail.quality[i] = attribute.value;
      }

      if (attribute.order) {
        this.order.product_detail.quality[i].order = attribute.order;
      }
    }

    // Default
    this.order.business_detail.quantity.unit = this.order.product.quantity_units[0];
    this.order.business_detail.price.quantity_unit = this.order.product.quantity_units[0];

    this.loadOrders();
  }

  public setDeliveryRange(days: number = 45): void {
    // Sets delivery range, from today to 'days' days after
    // Default value 45
    this.order.business_detail.delivery.range = [
      this.today_start,
      new Date(this.today_start.getTime() + (days * 24 * 60 * 60 * 1000))
    ];
  }

  public changePaymentCondition(): void {
    this.order.payment_detail.date = null;
    this.order.payment_detail.other = undefined;
  }

  /** Opens modal. */
  public show(): void {
    this.mode = 'manual';
    this.loadMarketData();
    this.openModal(this.modalTemplate);
  }

  /** Modal's form submit. */
  public done(): void {
    this.succesfullySubmitted = true;
    this.closeModal();
    this.success.emit();
  }

  public setMode(mode: 'manual' | 'market'): void {
    if (mode !== this.mode) {
      this.mode = mode;
      this.loadOrders();
    }
  }

  public ordersByProduct: GroupBy<Product, Order>[];

  private loadOrders(): void {
    if (this.mode === 'market') {
      this.ordersByProduct = undefined;
      if (this.order) {
        let filters = {
          operation_type: 'compra',
          product_id: this.order.product.id
        };

        if (this.ordersFrom) {
          const ids = this.ordersFrom.filter(company => company.id !== this.company.id).map(company => company.id);
          if (ids.length) filters['filters[companies][id]'] = ids.join(',');
        }

        this.subscriptions.push(this.orderService.getWorkingOrders(this.company.id, filters).subscribe(ordersByProduct => {
          this.ordersByProduct = ordersByProduct;
        }));
      }
    }
  }

  private checkFromDate(): void {
    const { delivery } = this.order.business_detail;

    if (delivery.date_from.getTime() < this.today_start.getTime()) {
      const diff = delivery.date_to.getTime() - delivery.date_from.getTime();
      this.setDeliveryRange(Math.round(diff / (24 * 60 * 60 * 1000)))
    };
  }

  public applyOrder(order: Order): void {
    let tempOrder = instanceToInstance(order);

    tempOrder.business_detail.quantity.type = { id: 1, slug: 'fixed', name: 'Fija' };
    tempOrder.business_detail.quantity.value = undefined;

    this.order.product = tempOrder.product;
    this.order.product_detail = tempOrder.product_detail;
    this.order.business_detail = tempOrder.business_detail;
    this.order.payment_detail = tempOrder.payment_detail;
    this.order.arbitration_chamber = tempOrder.arbitration_chamber;
    this.checkFromDate();

    this.done();
  }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      if (!this.succesfullySubmitted) this.onCancel.emit();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
