'use strict';

var numToStr = Number.prototype.toString;
var tryNumberObject = function tryNumberObject(value) {
  try {
    numToStr.call(value);
    return true;
  } catch (e) {
    return false;
  }
};
var toStr = Object.prototype.toString;
var numClass = '[object Number]';
var hasToStringTag = require('has-tostringtag/shams')();
module.exports = function isNumberObject(value) {
  if (typeof value === 'number') {
    return true;
  }
  if (typeof value !== 'object') {
    return false;
  }
  return hasToStringTag ? tryNumberObject(value) : toStr.call(value) === numClass;
};